<template>
  <div class="user-points-content pd-10 h-calc-60">
      <div class="search-content">
          <el-form :inline="true">
              <el-form-item label="类型">
                  <el-select v-model.number="type" placeholder="选择类型">
                      <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.type"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="手机号">
                  <el-input v-model="phoneNumber" placeholder="手机号" />
              </el-form-item>
              <el-form-item>
                  <el-button type="primary" @click="searchData">
                      <el-icon>
                        <Search />
                      </el-icon>
                  </el-button>
              </el-form-item>
          </el-form>

      </div>
      <div class="el-table-data">
          <el-table :data="tableData" height="100%">
              <el-table-column label="序号" width="80" align="center">
                  <template #default="scope">
                      {{scope.$index + 1}}
                  </template>
              </el-table-column>
              <el-table-column prop="nickname" label="昵称" width="130" align="center"></el-table-column>
              <el-table-column prop="mobile" label="手机号" align="center" width="200"></el-table-column>
              <el-table-column label="头像" width="150" >
                  <template #default="scope">
                      <div class="avatar-img">
                        <img :src="scope.row.avatar_url" :alt="scope.row.nickname" >
                      </div>
                  </template>
              </el-table-column>
              <el-table-column align="center" label="操作" width="120">
                  <template #default="scope">
                      {{action(scope.row.type)}}
                  </template>
              </el-table-column>
              <el-table-column align="center" label="变动前积分" prop="before_integral" width="150"></el-table-column>
              <el-table-column align="center" label="花掉积分" prop="integral" width="150"></el-table-column>
              <el-table-column align="center" label="变动后积分" prop="after_integral" width="150"></el-table-column>
              <el-table-column align="center" label="备注" prop="note"></el-table-column>
              <el-table-column align="center" label="添加时间"  prop="create_time" width="200"></el-table-column>
          </el-table>
      </div>
      <page-component :page="page" :limit="limit" :total="total" @change="pageChange" />
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {pointsList} from "@/utils/apis";
import {Search} from "@element-plus/icons-vue";
import PageComponent from "@/components/common/PageComponent"

const tableData = ref([])
const typeList = ref([
  {type: 0, name: '全部'},
  {type: 1, name: '签到'},
  {type: 2, name: '兑换礼品'}
])
const type = ref(0)
const phoneNumber = ref('')
const total = ref(0)
const page = ref(1)
const limit = ref(10)

const action = (type) => {
    return parseInt(type) === 1 ? '签到' : '兑换礼品'
}
const getPoints = async () => {
    let param = {
        page: page.value,
        pageSize: limit.value,
        paging: 1
    }
    if (type.value) {
        param.type = type.value
    }
    if (phoneNumber.value) {
        param.mobile = phoneNumber.value.trim()
    }
    const {data, msg} = await pointsList(param)
    tableData.value = data.list
    total.value = data.total
    console.log('data', data);
}
const pageChange = (val) => {
    console.log('val',val);
    page.value = val
    getPoints()
}
const searchData = () => {
    getPoints()
}
onMounted(() => {
    getPoints()
})

</script>

<style scoped lang="scss">
.user-points-content {
    .search-content {
        padding-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .el-table-data {
        padding: 20px 0;
        height: calc(100% - 130px);
        .avatar-img {
            width: 80px;
            height: 80px;
            border-radius: 4px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
        }
    }
}

</style>